import { AfterViewInit, Directive } from '@angular/core';
import { IgxGridComponent } from '@infragistics/igniteui-angular';

@Directive({
    selector: '[appGridCount]',
    standalone: true,
})
export class GridCountDirective implements AfterViewInit {

  constructor(private hostComponent: IgxGridComponent) {
    
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateFooterText(this.hostComponent);
    }, 100);
   
  }

  insertNode(grid: IgxGridComponent, parentElement: HTMLElement, firstChild: HTMLElement, newNode: HTMLElement){
    newNode.innerText = `Viser ${grid.totalRowsCountAfterFilter} av ${grid.dataLength}`
    newNode.setAttribute("id", "grid-count");
    parentElement.insertAdjacentElement("afterbegin", newNode);
  }

  private updateFooterText(grid: IgxGridComponent): void {
    try {      
      
      grid.footer.nativeElement.style.padding = '.5rem';
      const parentElement = grid.footer.nativeElement.firstChild;
      const firstChild = parentElement.firstChild;
      const newNode = document.createElement("span")

      this.insertNode(grid, parentElement, firstChild, newNode)

      grid.dataChanged.subscribe({
        next: () => {
          this.insertNode(grid, parentElement, firstChild, newNode)
        }
      })
    

      grid.filteringDone.subscribe({
        next: () => {
          parentElement.firstChild.innerText = `Viser ${grid.filteredData.length} av ${grid.dataLength}`;
        }
      })
    } catch (error) {
      
      
    }
   
   
  }
}




/*

 setTimeout(() => {

      // Subscribe to filteringDone event of the IgxGridComponent to update the footer text
      const grid = this.host as ElementRef<IgxGridComponent>;
      this.updateFooterText(grid.nativeElement);

      
      
      if (grid) {
        
        // grid.nativeElement..subscribe(() => {
        //   this.updateFooterText(grid.nativeElement);
        // });
      }
    }, 100);


     // Find the grid and its footer element by class name or another selector
    let footer = grid ? document.querySelector('igx-grid-footer') as IgxGridFooterComponent : null; // Adjust the selector as needed
    if (footer !== null) {
      
      
      // const filteredDataLength = grid.filteredData.length;
      // const totalItemCount = grid.totalItemCount;
      // footer. // = `${filteredDataLength} av ${totalItemCount}`;
    }

*/