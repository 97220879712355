import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
    standalone: true
})
export class TitleComponent implements OnInit {
  constructor() { } 
  @Input() value: string = '';
  ngOnInit(): void {
  }
}
